<template>
  <vueper-slides
    fade
    autoplay
    :touchable="true"
    :fixed-height="true"
    :breakpoints="breakpoints"
  >
    <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image" />
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      breakpoints: {
        900: {
          slideRatio: 1 / 3
        },
        600: {
          slideRatio: 16 / 9,
          bullets: false
        }
      },
      slides: []
    };
  },
  mounted() {
    for (let i = 1; i < 16; i++) {
      this.slides.push({
        image: require(`@/assets/images/2018/${i}.jpg` || "")
      });
    }
  }
};
</script>

<style>
.vueperslides--fixed-height {
  height: 100%;
}
.vueperslides__arrow {
  color: rgba(24, 26, 27, 0.75);
}
</style>
