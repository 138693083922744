<template>
  <div>
    <div class="about-header">
      <div class="container">
        <div class="row landing-section">
          <div class="evora-text col-12">
            <div class="main-text">
              <h2 class="display-3 fw-bold py-3 text-center">
                الصباغ® للصناعات الكهربائية
              </h2>
              <!-- <h4 class="display-6">للصناعات الكهربائية</h4> -->
              <!-- <p class="h2">
                تعد <strong class="fw-bold">Evora</strong> وجميع العلامات
                التجارية التابعة لشركة الصباغ للصناعات الكهربائية علامات مسجلة
                ومرخصة في مديرية التراخيص التجارية التابعة لوزارة الصناعة في
                الجمهورية العربية المصرية
              </p> -->
              <p class="h3">
                تشهد الالفية الجديدة نجم ساطع فى مجال تصنيع الادوات الكهربائية
                فى السوق المصرى بدء من عام 1997
              </p>
              <br />
              <p class="h3">
                شركة الصباغ هي إحدى الشركات الرائدة في مجال الصناعات الهندسية
                الكهربائية بالشرق الأوسط حيث تمتلك الشركة الخبرة العالية
                والخبراء المميزين فى إجراء البحوث و التجارب على طرق الإنارة
                الحديثة و التى تم تطوير العديد منها ، وهي أيضاً رائدة بترشيد
                الاستهلاك و التوفير الفعلي للطاقة الكهربائية، حيث أنه تمكنت من
                توفير الإضاءة المرتفعة بإستهلاك منخفض ومن المنتجات التى تقدمها
                الشركة لعملائها .
              </p>
              <br />
              <p class="h3">
                وبفضل من الله قد نالت منتجات الشركة ثقة المستهلك على الدوام فى
                داخل وخارج مصر وايضا من أهم المميزات الخاصة التى تتميز بها
                الشركة أنها تقدم للعملاء الضمان الحقيقى و خدمة حقيقية بعد البيع
                وايضا تتميز منتجاتها بجودة الخامات مما ينتج عنه إخراج منتج نهائى
                بمواصفات عالمية على أرض مصرية .
              </p>

              <!-- <p>تأسست سنة 1997</p> -->
            </div>
          </div>

          <!-- <div
            class="evora-image col-sm-12 col-md-5 order-1 order-md-1 overflow-hidden d-flex align-items-center"
          >
            <img src="../assets/images/evora-logo.svg" alt="" />
          </div> -->
        </div>
      </div>
    </div>

    <!-- Start our-brands -->

    <div class="brands">
      <div class="container">
        <!-- <div class="row py-2 justify-content-center"> -->
        <div class="py-2">
          <!-- <div class="col-sm-12 col-md-4">
          </div> -->
          <img src="../assets/images/marks/marks-01.png" alt="kenzy" />
          <!-- <div class="col-sm-12 col-md-4">
          </div> -->
          <img src="../assets/images/marks/marks-02.png" alt="" />
          <!-- <div class="col-sm-12 col-md-4">
            <img
              src="../assets/images/marks/marks-03.png"
              class="img-fluid my-2"
              alt=""
            />
          </div> -->
          <!-- <div class="col-sm-12 col-md-4">
          </div> -->
          <img src="../assets/images/marks/marks-04.png" alt="" />
        </div>
      </div>
    </div>

    <!-- End our-brands -->

    <!-- Start led expo 2021 -->

    <div class="container-fluid expo-new">
      <div class="container text-center h2 py-5">
        <h3 class="display-4 my-4">انتظرونا في</h3>
        <h1 class="display-2 my-4"><u>المعرض الدولي الرابع</u></h1>
        <h3 class="display-4 my-4">
          جناح <span class="text-danger">B04</span>
        </h3>
        <img
          class="img-fluid"
          src="../assets/images/Led-Expo-web.png"
          alt="middle east Expo"
          loading="lazy"
        />
        <h3 class="display-5 my-4">للمزيد من التفاصيل</h3>
        <a
          href="http://ledmiddleeast-expo.com/"
          target="blank"
          class="btn btn-lg btn-dark"
          >المزيد ...</a
        >
      </div>
    </div>

    <!-- End led expo 2021 -->
    <div class="facebook">
      <div class="container">
        <div class="my-auto">
          <!-- <h2 class="display-5">
              يمكنكم متابعتنا على وسائل التواصل الاجتماعي
            </h2> -->
          <q class="h2">
            تابعونا دائما لمعرفة الجديد من منتجات الشركة التى تحظى دائما بثقة
            المستهلك
          </q>
          <div class="social-shape">
            <a
              href="https://www.facebook.com/100222895445465"
              title="Facebook"
              target="blank"
              ><i class="fab fa-facebook display-4"></i
            ></a>
            <a
              href="https://www.youtube.com/channel/UC0uNSCiMI6BNI0ZtQOrsr6A"
              title="Youtube"
              target="blank"
              ><i class="fab fa-youtube display-4"></i
            ></a>
            <a
              href="https://twitter.com/Sabagh1997"
              title="Twitter"
              target="blank"
              ><i class="fab fa-twitter display-4"></i
            ></a>
            <a
              href="https://www.instagram.com/moon.light.solution/"
              title="Telegram"
              target="blank"
              ><i class="fab fa-instagram display-4"></i
            ></a>
            <a href="https://t.me/sabbaghlight" title="Telegram" target="blank"
              ><i class="fab fa-telegram-plane display-4"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Start led expo 2018 -->

    <div class="container-fluid">
      <div class="container text-center h2 py-5">
        <h1 class="display-2 my-4">مشاركتنا في المعرض الدولي الثاني 2018</h1>
        <p>على أرض مدينة المعارض</p>
        <div class="old-carousel">
          <CarouselExpoOld />
        </div>
        <div class="row my-5">
          <div class="col-12">
            <iframe
              class="youtube-frame"
              src="https://www.youtube.com/embed/WxRB90yzJWY"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <!-- End led expo 2018 -->

      <!-- start location  -->

      <div class="location py-5">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6 my-auto">
              <h2 class="display-4 my-4">العنوان الرئيسي للشركة :</h2>
              <p class="h3">
                مقر الشركة الرئيسي: مصر - العاشر من رمضان - المنطقة الصناعية
                الثالثة
              </p>
            </div>
            <div class="col-sm-12 col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3445.4717748631488!2d31.7883463!3d30.2806283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1457fdff0f89f0ef%3A0x3eff10d4c3345f67!2z2LTYsdmD2Kkg2KfZhNi12KjYp9i6INmE2YTYtdmG2KfYudin2Kog2KfZhNmD2YfYsdio2KfYptmK2Yc!5e0!3m2!1sen!2str!4v1615195052260!5m2!1sen!2str"
                width="100%"
                height="450"
                class="border border-dark"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <!-- end location -->

      <div class="Youtube py-5">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6 my-auto order-2">
              <h2 class="display-4">مراحل التصنيع :</h2>
              <p class="lead">
                تتم عملية التصنيع والتجميع و التغليف والتعبئة داخل المصنع بدقة
                وإتقان حرصاً على جودة المنتج ومطابقته لأعلى المعايير والموصفات
                العالمية
              </p>
            </div>
            <div class="col-sm-12 col-md-6 order-1">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/WxRB90yzJWY"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselExpoOld from "../components/CarouselExpoOld";
export default {
  components: {
    CarouselExpoOld
  }
};
</script>

<style scoped>
.old-carousel {
  padding: 1em 0;
  height: 100vh !important;
}
@media (max-width: 576px) {
  .old-carousel {
    max-height: 350px;
  }
}

.container .py-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1%;
}

.container .py-2 > img {
  height: 15rem;
  max-width: 100%;
}

.social-shape a {
  transition: all 0.1s ease;
}
</style>
